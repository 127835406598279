import React from "react";

export const Features = (props) => {
  return (
    // <div id="features" className="text-center">
    //   <div className="container" style={{ paddingBottom: "10px" }}>
    //     <div
    //       className="col-md-10 col-md-offset-1 section-title"
    //       style={{ marginBottom: "0px" }}
    //     >
    //       <h2>Collaboration</h2>
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         height: "300px",
    //       }}
    //     >
    //       <div
    //         className="row"
    //         style={{
    //           display: "flex",
    //           flexDirection: "row",
    //           justifyContent: "space-evenly",
    //           alignItems: "center",
    //         }}
    //       >
    //         {props.data
    //           ? props.data.map((d, i) => (
    //               <div
    //                 key={`${d.title}-${i}`}
    //                 className="col-xs-6 col-md-3"
    //                 style={{
    //                   width: "180px",
    //                   height: "180px",
    //                 }}
    //               >
    //                 {" "}
    //                 <img className="col-logo" src={d.logo}></img>
    //                 {/* <i className={d.icon}></i>
    //               <h3>{d.title}</h3>
    //               <p>{d.text}</p> */}
    //               </div>
    //             ))
    //           : "Loading..."}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div id="projects" className="text-center">
      <div className="container" style={{ paddingBottom: "10px" }}>
        <div
          className="col-md-10 col-md-offset-1 section-title"
          style={{ marginBottom: "20px" }}
        >
          <h2>Collaboration</h2>
        </div>
        <div className="row">
          <div
            key={1}
            className="col-xs-6 col-md-3"
            style={{ padding: "10px" }}
          >
            <div
              className="logo-container"
              style={{
                textAlign: "center",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="col-logo"
                src={"img/collaborate/mbzuai_logo.jpg"}
                alt={"cmu"}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div
            key={1}
            className="col-xs-6 col-md-3"
            style={{ padding: "10px" }}
          >
            <div
              className="logo-container"
              style={{
                textAlign: "center",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="col-logo"
                src={"img/collaborate/cmu_combine.png"}
                alt={"cmu"}
                style={{
                  maxWidth: "70%",
                  maxHeight: "60%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div
            key={1}
            className="col-xs-6 col-md-3"
            style={{ padding: "10px" }}
          >
            <div
              className="logo-container"
              style={{
                textAlign: "center",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="col-logo"
                src={"img/collaborate/usydney.png"}
                alt={"usydney"}
                style={{
                  maxWidth: "80%",
                  maxHeight: "70%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div
            key={1}
            className="col-xs-6 col-md-3"
            style={{ padding: "10px" }}
          >
            <div
              className="logo-container"
              style={{
                textAlign: "center",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="col-logo"
                src={"img/collaborate/conahyct.svg"}
                alt={"cmu"}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
