import React from "react";

export const Team = (props) => {
  const styles = {
    // row: {
    //   display: "flex",
    //   flexWrap: "wrap",
    //   justifyContent: "space-between",
    // },
    team: {
      marginBottom: "30px",
    },
    thumbnail: {
      // overflow: "hidden",
      // borderRadius: "10px",
      // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    teamImg: {
      width: "150px",
      height: "150px", // Set the desired height
      objectFit: "cover",
      borderRadius: "50%",
    },
    teamImg_top: {
      width: "150px",
      height: "150px", // Set the desired height
      objectFit: "cover",
      borderRadius: "50%",
      objectPosition: "top",
    },
    caption: {
      padding: "15px",
      textAlign: "center",
    },
  };

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div id="row" style={styles.row}>
          {/* <div
            key={`image-0`}
            className="col-xs-12 col-md-4 team"
            style={styles.team}
          >
            <div className="thumbnail" style={styles.thumbnail}>
              {" "}
              <img
                src={"img/team/kunzhang.webp"}
                alt="..."
                className="team-img"
                style={styles.teamImg}
              />
              <div className="caption" style={styles.caption}>
                <h4>Prof. Kun Zhang</h4>
                <p>Chairman</p>
              </div>
            </div>
          </div> */}
          <div
            key={`image-0`}
            className="col-xs-12 col-md-4 team"
            style={styles.team}
          >
            <div className="thumbnail" style={styles.thumbnail}>
              <img
                src={"img/team/kunzhang.webp"}
                alt="..."
                className="team-img"
                style={styles.teamImg}
              />
              <div className="caption" style={styles.caption}>
                <h4>Prof. Kun Zhang</h4>
                <p>Chairman</p>
              </div>
            </div>
          </div>

          <div
            key={`image-1`}
            className="col-xs-12 col-md-4 team"
            style={styles.team}
          >
            <div className="thumbnail" style={styles.thumbnail}>
              <img
                src={"img/team/yuewen.jpg"}
                alt="..."
                className="team-img"
                style={styles.teamImg_top}
              />
              <div className="caption" style={styles.caption}>
                <h4>Dr. Yuewen Sun</h4>
                <p>CEO</p>
              </div>
            </div>
          </div>

          <div
            key={`image-2`}
            className="col-xs-12 col-md-4 team"
            style={styles.team}
          >
            <div className="thumbnail" style={styles.thumbnail}>
              <img
                src={"img/team/yukang.jpeg"}
                alt="..."
                className="team-img"
                style={styles.teamImg}
              />
              <div className="caption" style={styles.caption}>
                <h4>Yu Kang Wong</h4>
                <p>COO</p>
              </div>
            </div>
          </div>

          <div
            key={`image-3`}
            className="col-xs-12 col-md-4 team"
            style={styles.team}
          >
            <div className="thumbnail" style={styles.thumbnail}>
              <img
                src={"img/team/minghao.jpeg"}
                alt="..."
                className="team-img"
                style={styles.teamImg}
              />
              <div className="caption" style={styles.caption}>
                <h4>Minghao Fu</h4>
                <p>CTO</p>
              </div>
            </div>
          </div>

          <div
            key={`image-4`}
            className="col-xs-12 col-md-4 team"
            style={styles.team}
          >
            <div className="thumbnail" style={styles.thumbnail}>
              <img
                src={"img/team/man_svg.svg"}
                alt="..."
                className="team-img"
                style={styles.teamImg}
              />
              <div className="caption" style={styles.caption}>
                <h4>Yifan Shen</h4>
                <p>Project Manager</p>
              </div>
            </div>
          </div>

          <div
            key={`image-5`}
            className="col-xs-12 col-md-4 team"
            style={styles.team}
          >
            <div className="thumbnail" style={styles.thumbnail_top}>
              <img
                src={"img/team/yaoyu.jpeg"}
                alt="..."
                className="team-img"
                style={styles.teamImg_top}
              />
              <div className="caption" style={styles.caption}>
                <h4>Dr. Yu Yao</h4>
                <p>Advisor</p>
              </div>
            </div>
          </div>

          {/* {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-xs-12 col-md-4 team"
                  style={styles.team}
                >
                  <div className="thumbnail" style={styles.thumbnail}>
                    {" "}
                    <img
                      src={d.img}
                      alt="..."
                      className="team-img"
                      style={styles.teamImg}
                    />
                    <div className="caption" style={styles.caption}>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    </div>
  );
};
