import React from "react";

const Quote = ({ text, author }) => {
  return (
    <div id="quote" style={styles.container}>
      <div style={styles.leftQuoteIcon}>&ldquo;</div>
      <p style={styles.text}>{text.toUpperCase()}</p>
      <p style={styles.author}>- {author}</p>
      <div style={styles.rightQuoteIcon}>&rdquo;</div>
    </div>
  );
};

const styles = {
  container: {
    // maxWidth: "100%",
    margin: "0px auto",
    padding: "20px",
    textAlign: "center",
    // backgroundColor: "#5ca9fb",
    backgroundImage: "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)",
    borderRadius: "0px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
  },
  leftQuoteIcon: {
    fontSize: "100px",
    color: "white",
    textAlign: "start",
    fontWeight: "5000",
  },
  rightQuoteIcon: {
    fontSize: "100px",
    color: "white",
    textAlign: "end",
    fontWeight: "5000",
  },
  text: {
    fontSize: "40px",
    fontStyle: "italic",
    color: "white",
    margin: "20px 0",
  },
  author: {
    fontSize: "20px",
    color: "white",
  },
};

export default Quote;
