import React from "react";
import { Testimonials } from "./testimonials";
import Quote from "./quote";

export const Project = (props) => {
  console.log(props.data);
  window.scrollTo(0, 0);
  return (
    <div>
      <header id="header">
        <div
          className="intro"
          style={{
            background: props.data
              ? `url(${props.data.header_img}) center center no-repeat`
              : "none",
            backgroundSize: "cover",
          }}
        >
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  {/* <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {props.data && props.data.quote && (
        <Quote
          text={props.data ? props.data.quote.text : "Loading..."}
          author={props.data ? props.data.quote.author : "Loading..."}
        />
      )}

      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Features</h2>
            {/* <p>{props.data ? props.data.project_function : "Loading..."}</p> */}
          </div>
          <div className="row">
            {props.data
              ? props.data.features.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-12 col-md-4">
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src={props.data ? props.data.app_image : null}
                className="img-responsive"
                style={{ width: "100%" }}
                alt=""
              />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <h2>{props.data ? props.data.about_title : "Loading..."}</h2>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              <h3>{props.data ? props.data.about_subtitle : "Loading..."}</h3>
              <div className="about-text">
                <p>
                  {props.data ? props.data.project_function_1 : "Loading..."}
                </p>
                <p>
                  {props.data ? props.data.project_function_2 : "Loading..."}
                </p>
              </div>
              <div className="store-section">
                {props.data && props.data.google_link ? (
                  <a href={props.data.google_link} target="_blank">
                    <img
                      src="img/google_play.png"
                      style={{ width: "200px", margin: "5px" }}
                    ></img>
                  </a>
                ) : null}
                {props.data && props.data.apple_link ? (
                  <a href={props.data.apple_link} target="_blank">
                    <img
                      src="img/apple_store.png"
                      style={{ width: "200px", margin: "5px" }}
                    ></img>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.testimonials.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div> */}
    </div>
  );
};
