import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import ProjectData from "./data/projectData.json";
import SmoothScroll from "smooth-scroll";
import { Project } from "./components/project";

import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [projectData, setProjectData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    setProjectData(ProjectData);
  }, []);

  const Layout = ({ children }) => (
    <>
      <Navigation />
      {children}
    </>
  );

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Header data={landingPageData.Header} />
              <About data={landingPageData.About} />
              <Features data={landingPageData.Features} />
              <Services data={landingPageData.Services} />
              {/* <Gallery data={landingPageData.Gallery} /> */}
              {/* <Testimonials data={landingPageData.Testimonials} /> */}
              <Team data={landingPageData.Team} />
              <Contact data={landingPageData.Contact} />
            </Layout>
          }
        />
        <Route
          path="/project/:id"
          element={
            <Layout>
              <Project data={projectData.letbabytalk} />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

// https://github.com/issaafalkattan/React-Landing-Page-Template/tree/master
// https://medium.com/@rizkiprass/step-by-step-guide-deploying-a-react-app-on-aws-ec2-b2965af05aa4

// sudo service apache2 stop
