// import React from "react";
// import { Link } from "react-router-dom";

// export const Services = (props) => {
//   return (
//     <div id="services" className="text-center">
//       <div className="container">
//         <div className="section-title">
//           <h2>Our Projects</h2>
//           {/* <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
//             dapibus leonec.
//           </p> */}
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-md-4">
//                   <Link
//                     to={`/project/${d.id}`}
//                     className="project-link"
//                     style={{ textDecoration: "none", display: "block" }}
//                   >
//                     <div
//                       className="service-desc"
//                       style={{
//                         padding: "10px",
//                         margin: "0px",
//                         border: "2px solid transparent",
//                         transition:
//                           "border 0.3s ease-in-out, background-color 0.3s ease-in-out",
//                       }}
//                       onMouseEnter={(e) => {
//                         e.currentTarget.style.border = "2px solid white";
//                         e.currentTarget.style.backgroundColor =
//                           "rgba(255, 255, 255, 0.1)"; // Optional highlight effect
//                       }}
//                       onMouseLeave={(e) => {
//                         e.currentTarget.style.border = "2px solid transparent";
//                         e.currentTarget.style.backgroundColor = "transparent"; // Optional highlight effect
//                       }}
//                     >
//                       <i className={d.icon}>
//                         <img
//                           src={d.image}
//                           style={{ width: "100px", height: "100px" }}
//                         ></img>
//                       </i>
//                       <h3>{d.name}</h3>
//                       <p>{d.text}</p>
//                     </div>
//                   </Link>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>
//     </div>
//   );
// };
import React from "react";
import { Link } from "react-router-dom";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Project</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-xl-12"
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className="service-desc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      margin: "0px",
                      border: "2px solid transparent",
                      transition:
                        "border 0.3s ease-in-out, background-color 0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "2px solid white";
                      e.currentTarget.style.backgroundColor =
                        "rgba(255, 255, 255, 0.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "2px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    <div
                      className="project-image"
                      style={{
                        flex: "0 0 150px",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: "0%",
                        width: "150px",
                        height: "150px",
                        overflow: "hidden",
                        margin: "20px",
                      }}
                    >
                      <img
                        src={d.image}
                        style={{
                          width: "150px",
                          height: "150px",
                          // borderRadius: "100%",
                        }}
                        alt={d.name}
                      />
                    </div>
                    <div style={{ flex: "1", padding: "10px" }}>
                      <h3
                        style={{
                          fontSize: "30px",
                          textAlign: "left",
                          fontWeight: "1000",
                        }}
                      >
                        {d.name}
                      </h3>
                      <p style={{ textAlign: "left" }}>{d.text}</p>
                      <p style={{ textAlign: "left" }}>
                        Please try our product online at{" "}
                        <a
                          style={{ color: "rgba(255, 255, 255, 0.75)" }}
                          href="https://www.letbabytalk.com"
                          target="_blank"
                        >
                          www.letbabytalk.com
                        </a>
                      </p>

                      <Link
                        to={`/project/${d.id}`}
                        className="project-link"
                        style={{
                          textDecoration: "none",
                          display: "block",
                          textAlign: "center",
                        }}
                      >
                        <a
                          href=""
                          className="btn btn-custom btn-lg page-scroll"
                        >
                          Learn More
                        </a>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
